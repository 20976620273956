export const themeList = [
  {
    id: 1,
    name: "default Theme",
    isActive: true,
    createdAt: "2022-07-07T22:04:49.211Z",
    updatedAt: "2022-07-07T22:04:49.211Z",
    elements: {
      type: "light",
      direction: "ltr",
      fontFamily: {
        ar: "next",
        en: "poppins",
        sans: "sans-serif",
        poppinsBold: "poppinsB",
      },
      fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
      fontSize: {
        xxs: 11,
        xs: 13,
        ss: 14,
        s: 16,
        m: 18,
        sm: 20,
        ms: 22,
        mm: 24,
        sg: 26,
        gg: 28,
        lg: 30,
        llg: 36,
        xl: 40,
        l: 45,
        xll: 50,
        xxl: 60,
        cardTitle: 18,
        cardDescription: 14,
        homeSectionTitle: 45,
        grayDescription: 14,
        generalTitle: 30,
      },
      fontSizeG: [
        11, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 40, 45, 50, 60, 18, 14,
        45, 14, 30,
      ],
      fontWeight: { xs: 200, xxs: 300, s: 400, m: 500, l: 600, ll: 700 },
      colors: {
        cardBackground: "#fff",
        white: "#fff",
        black: "#000",
        darkblack: "#070707",
        lightHeaderColor: "rgba(10,19,33,.72)",
        blackColor: "#051a2b",
        contactTabColor: "#495057",
        descrptionBranch: "#717171",
        contactIcon: "#DADADA",
        dialogIcon: "#b4b4b4",
        dialogItem: "#f0f0f0",
        darkcolor: "#010610",
        contactTab: "#B3B3B3",
        youTubeIcon: "#FF0000",
        contactActive: "#B663CE",
        linkedInIcon: "#004182",
        emailIcon: "#BDBDBD",
        TwitterIcon: "#55ACEE",
        dropZoneColor: "rgb(113, 113, 113)",
        primary: "#288fce",
        greenLink: "#169179",
        blueColor: "rgb(0, 74, 139)",
        green: "#6a3389",
        blue: "#0096fb",
        darkGray: "#4f4f4f",
        descProduct: "rgba(51, 51, 51, 0.58)",
        titleProduct: "rgb(7, 7, 7)",
        lightGray: "#8f8d8d",
        textFieldColor: "#f4f4f4",
        purpuleColor: "rgb(138, 85, 136)",
        facebookIcon: "#3B5998",
        pinkColor: "rgb(191, 61, 186)",
        greenColor: "rgb(92, 130, 153)",
        lightBlue: "#c0dded",
        textSecondary: "#888",
        lightBlack: "#333",
        secondary: "#D45D00",
        instagramIcon: "#9E74D3",
        tertiary: "#143663",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#E59150",
        imageBorder: "#F5F5F5",
        darkpurple: "#6e90c3",
        titleServices: "#777",
        violate: "#bd99c3",
        darkblue: "#8874a3",
        lightGrayCard: "#848484",
        homeSectionTitle: "#000",
        grayDescription: "#848484",
        whiteDescription: "#fff",
        cardTitle: "rgb(7, 7, 7)",
        generalTitle: "#000",
        disableText: "rgba(0,0,0,0.6)",
        textUpdate: "#288fce",
        gradiantBlue: "linear-gradient(45deg,rgb(87 164 214),rgb(165 204 230))",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",
      },
    },
  },
  {
    id: 2,
    name: "Blue Theme",
    isActive: true,
    createdAt: "2022-07-07T22:04:49.211Z",
    updatedAt: "2022-07-07T22:04:49.211Z",
    elements: {
      type: "blue",
      direction: "ltr",
      fontFamily: {
        ar: "next",
        en: "poppins",
        sans: "sans-serif",
        poppinsBold: "poppinsB",
      },
      fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
      fontSize: {
        xxs: 11,
        xs: 13,
        ss: 14,
        s: 16,
        m: 18,
        sm: 20,
        ms: 22,
        mm: 24,
        sg: 26,
        gg: 28,
        lg: 30,
        llg: 36,
        xl: 40,
        l: 45,
        xll: 50,
        xxl: 60,
        cardTitle: 18,
        cardDescription: 14,
        homeSectionTitle: 45,
        grayDescription: 14,
        generalTitle: 30,
      },
      fontSizeG: [
        11, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 40, 45, 50, 60, 18, 14,
        45, 14, 30,
      ],
      fontWeight: { xs: 200, xxs: 300, s: 400, m: 500, l: 600, ll: 700 },
      colors: {
        cardBackground: "#fff",
        white: "#fff",
        black: "#000",
        darkblack: "#070707",
        lightHeaderColor: "rgba(10,19,33,.72)",
        blackColor: "#051a2b",
        contactTabColor: "#495057",
        descrptionBranch: "#717171",
        contactIcon: "#DADADA",
        dialogIcon: "#b4b4b4",
        dialogItem: "#f0f0f0",
        darkcolor: "#010610",
        contactTab: "#B3B3B3",
        contactActive: "#B663CE",
        dropZoneColor: "rgb(113, 113, 113)",
        primary: "#3E4A6E",
        TwitterIcon: "#55ACEE",
        facebookIcon: "#3B5998",
        linkedInIcon: "#004182",
        youTubeIcon: "#FF0000",
        instagramIcon: "#9E74D3",
        emailIcon: "#BDBDBD",
        greenLink: "#169179",
        textUpdate: "#288fce",
        blueColor: "#3E4A6E",
        green: "#6a3389",
        blue: "#3E4A6E",
        darkGray: "#4f4f4f",
        descProduct: "rgba(51, 51, 51, 0.58)",
        titleProduct: "rgb(7, 7, 7)",
        lightGray: "#8f8d8d",
        textFieldColor: "#f4f4f4",
        purpuleColor: "rgb(138, 85, 136)",
        pinkColor: "rgb(191, 61, 186)",
        greenColor: "rgb(92, 130, 153)",
        lightBlue: "#3E4A6E",
        textSecondary: "#888",
        lightBlack: "#333",
        secondary: "#D45D00",
        tertiary: "#143663",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#E59150",
        imageBorder: "#F5F5F5",
        darkpurple: "#6e90c3",
        titleServices: "#777",
        violate: "#bd99c3",
        darkblue: "#8874a3",
        lightGrayCard: "#848484",
        homeSectionTitle: "#000",
        grayDescription: "#848484",
        whiteDescription: "#fff",
        cardTitle: "rgb(7, 7, 7)",
        generalTitle: "#000",
        disableText: "rgba(0,0,0,0.6)",
        gradiantBlue: "linear-gradient(45deg,rgb(87 164 214),rgb(165 204 230))",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",

        blackText: "#000",
      },
    },
  },
  {
    id: 3,
    name: "Blind Theme",
    isActive: true,
    createdAt: "2022-10-04T13:41:49.211Z",
    updatedAt: "2022-10-04T13:41:49.211Z",
    elements: {
      type: "blind",
      direction: "rtl",
      fontFamily: {
        ar: "next",
        en: "poppins",
        sans: "sans-serif",
        poppinsBold: "poppinsB",
      },
      fontSize: {
        xxs: 11,
        xs: 13,
        ss: 14,
        s: 16,
        m: 18,
        sm: 20,
        ms: 22,
        mm: 24,
        sg: 26,
        gg: 28,
        lg: 30,
        llg: 36,
        xl: 40,
        l: 45,
        xll: 50,
        xxl: 60,
        cardTitle: 18,
        cardDescription: 14,
        homeSectionTitle: 45,
        grayDescription: 14,
        generalTitle: 30,
      },
      fontSizeG: [28, 24, 20, 16, 12],
      fontWeight: { xs: 200, xxs: 300, s: 400, m: 500, l: 600, ll: 700 },
      colors: {
        cardBackground: "#fff",
        white: "#fff",
        black: "#000",
        darkblack: "#070707",
        lightHeaderColor: "rgba(10,19,33,.72)",
        blackColor: "#051a2b",
        contactTabColor: "#495057",
        descrptionBranch: "#717171",
        contactIcon: "#DADADA",
        dialogIcon: "#b4b4b4",
        dialogItem: "#f0f0f0",
        darkcolor: "#010610",
        contactTab: "#B3B3B3",
        contactActive: "#B663CE",
        TwitterIcon: "#55ACEE",
        facebookIcon: "#3B5998",
        linkedInIcon: "#004182",
        youTubeIcon: "#FF0000",
        instagramIcon: "#9E74D3",
        emailIcon: "#BDBDBD",
        dropZoneColor: "rgb(113, 113, 113)",
        primary: "#288fce",
        greenLink: "#169179",
        blueColor: "rgb(0, 74, 139)",
        green: "#6a3389",
        blue: "#0096fb",
        darkGray: "#4f4f4f",
        descProduct: "rgba(51, 51, 51, 0.58)",
        titleProduct: "rgb(7, 7, 7)",
        lightGray: "#8f8d8d",
        textFieldColor: "#f4f4f4",
        purpuleColor: "rgb(138, 85, 136)",
        pinkColor: "rgb(191, 61, 186)",
        greenColor: "rgb(92, 130, 153)",
        lightBlue: "#c0dded",
        textSecondary: "#888",
        lightBlack: "#333",
        secondary: "#D45D00",
        tertiary: "#143663",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#E59150",
        imageBorder: "#F5F5F5",
        darkpurple: "#6e90c3",
        titleServices: "#777",
        violate: "#bd99c3",
        darkblue: "#8874a3",
        lightGrayCard: "#848484",
        homeSectionTitle: "#000",
        grayDescription: "#848484",
        whiteDescription: "#fff",
        cardTitle: "rgb(7, 7, 7)",
        generalTitle: "#000",
        gradiantBlue: "linear-gradient(45deg,rgb(87 164 214),rgb(165 204 230))",
        grayShadow:
          "radial-gradient(50% 50% at 50% 50%, #C7C7C7 0%, #E9E9E9 100%)",

        blackText: "#000",
      },
    },
  },
];
