import * as ACTION_TYPES from "../actions/actionTypes";
import { combineReducers } from "redux";
import { themeList } from "../../constants/theme";

const defaultTheme = localStorage.getItem("infoTheme")
  ? JSON.parse(localStorage.getItem("infoTheme"))
  : themeList[0];

const initialState = {
  isDark: Boolean(JSON.parse(localStorage.getItem("infoIsDark"))),
  currentTheme: defaultTheme,
  themeList,
  isRTL: Boolean(JSON.parse(localStorage.getItem("infoIsRTL"))),
};

const basicTheme = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.DARK_THEME:
      return {
        ...state,
        isDark: action.payload,
      };

    case ACTION_TYPES.SET_DIRECTION:
      return {
        ...state,
        isRTL: action.payload,

        currentTheme: {
          ...state.currentTheme,
          direction: action.payload === true ? "rtl" : "ltr",
        },
      };

    case ACTION_TYPES.CHANGE_THEME:
      return {
        ...state,
        currentTheme: action.payload,
      };

    case ACTION_TYPES.GET_THEME_LIST:
      return {
        ...state,
        themeList: action.payload.length ? action.payload : state.themeList,
      };

    case ACTION_TYPES.INCREASE_FONT:
      return {
        ...state,
        currentTheme: {
          ...state.currentTheme,
          elements: {
            ...state.currentTheme.elements,
            fontSize: {
              ...state.currentTheme.elements.fontSize,
              generalTitle:
                state.currentTheme.elements.fontSize.generalTitle + 1,
              grayDescription:
                state.currentTheme.elements.fontSize.grayDescription + 1,
              homeSectionTitle:
                state.currentTheme.elements.fontSize.homeSectionTitle + 1,
              cardDescription:
                state.currentTheme.elements.fontSize.cardDescription + 1,
              cardTitle: state.currentTheme.elements.fontSize.cardTitle + 1,
              xxl: state.currentTheme.elements.fontSize.xxl + 1,
              xll: state.currentTheme.elements.fontSize.xll + 1,
              l: state.currentTheme.elements.fontSize.l + 1,
              llg: state.currentTheme.elements.fontSize.llg + 1,
              sg: state.currentTheme.elements.fontSize.sg + 1,
              gg: state.currentTheme.elements.fontSize.gg + 1,
              mm: state.currentTheme.elements.fontSize.mm + 1,
              ms: state.currentTheme.elements.fontSize.ms + 1,
              xxs: state.currentTheme.elements.fontSize.xxs + 1,
              sm: state.currentTheme.elements.fontSize.sm + 1,
              ss: state.currentTheme.elements.fontSize.ss + 1,
              xl: state.currentTheme.elements.fontSize.xl + 1,
              lg: state.currentTheme.elements.fontSize.lg + 1,
              m: state.currentTheme.elements.fontSize.m + 1,
              s: state.currentTheme.elements.fontSize.s + 1,
              xs: state.currentTheme.elements.fontSize.xs + 1,
            },
          },
        },
      };

    case ACTION_TYPES.DECREASE_FONT:
      return {
        ...state,
        currentTheme: {
          ...state.currentTheme,
          elements: {
            ...state.currentTheme.elements,
            fontSize: {
              ...state.currentTheme.elements.fontSize,

              generalTitle:
                state.currentTheme.elements.fontSize.generalTitle - 1,
              grayDescription:
                state.currentTheme.elements.fontSize.grayDescription - 1,
              homeSectionTitle:
                state.currentTheme.elements.fontSize.homeSectionTitle - 1,
              cardDescription:
                state.currentTheme.elements.fontSize.cardDescription - 1,
              cardTitle: state.currentTheme.elements.fontSize.cardTitle - 1,
              xxl: state.currentTheme.elements.fontSize.xxl - 1,
              xll: state.currentTheme.elements.fontSize.xll - 1,
              l: state.currentTheme.elements.fontSize.l - 1,
              llg: state.currentTheme.elements.fontSize.llg - 1,
              sg: state.currentTheme.elements.fontSize.sg - 1,
              gg: state.currentTheme.elements.fontSize.gg - 1,
              mm: state.currentTheme.elements.fontSize.mm - 1,
              ms: state.currentTheme.elements.fontSize.ms - 1,
              xxs: state.currentTheme.elements.fontSize.xxs - 1,
              sm: state.currentTheme.elements.fontSize.sm - 1,
              ss: state.currentTheme.elements.fontSize.ss - 1,
              xl: state.currentTheme.elements.fontSize.xl - 1,
              lg: state.currentTheme.elements.fontSize.lg - 1,
              m: state.currentTheme.elements.fontSize.m - 1,
              s: state.currentTheme.elements.fontSize.s - 1,
              xs: state.currentTheme.elements.fontSize.xs - 1,
            },
          },
        },
      };

    case ACTION_TYPES.RESET_FONT:
      return {
        // xxs: 11,
        // xs: 13,
        // ss: 14,
        // s: 16,
        // m: 18,
        // sm: 20,
        // ms: 22,
        // mm: 24,
        // sg: 26,
        // gg: 28,
        // lg: 30,
        // llg: 36,
        // xl: 40,
        // l: 45,
        // xll: 50,
        // xxl: 60,
        // cardTitle: 18,
        // cardDescription: 14,
        // homeSectionTitle: 45,
        // grayDescription: 14,
        // generalTitle: 30,
        ...state,
        currentTheme: {
          ...state.currentTheme,
          elements: {
            ...state.currentTheme.elements,
            fontSize: {
              ...state.currentTheme.elements.fontSize,
              xxs: state.currentTheme.elements.fontSizeG[0],
              xs: state.currentTheme.elements.fontSizeG[1],
              ss: state.currentTheme.elements.fontSizeG[2],
              s: state.currentTheme.elements.fontSizeG[3],
              m: state.currentTheme.elements.fontSizeG[4],
              sm: state.currentTheme.elements.fontSizeG[5],
              ms: state.currentTheme.elements.fontSizeG[6],
              mm: state.currentTheme.elements.fontSizeG[7],
              sg: state.currentTheme.elements.fontSizeG[8],
              gg: state.currentTheme.elements.fontSizeG[9],
              lg: state.currentTheme.elements.fontSizeG[10],
              llg: state.currentTheme.elements.fontSizeG[11],
              xl: state.currentTheme.elements.fontSizeG[12],
              l: state.currentTheme.elements.fontSizeG[13],
              xll: state.currentTheme.elements.fontSizeG[14],
              xxl: state.currentTheme.elements.fontSizeG[15],
              cardTitle: state.currentTheme.elements.fontSizeG[16],
              cardDescription: state.currentTheme.elements.fontSizeG[17],
              homeSectionTitle: state.currentTheme.elements.fontSizeG[18],
              grayDescription: state.currentTheme.elements.fontSizeG[19],
              generalTitle: state.currentTheme.elements.fontSizeG[20],
            },
          },
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  basicTheme,
});
